<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'close sharp'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 10 10"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <path
    d="M6.187 5 9.9 1.286a.337.337 0 0 0 0-.477l-.71-.71a.337.337 0 0 0-.477 0L5 3.813 1.286.099a.337.337 0 0 0-.477 0l-.71.71a.337.337 0 0 0 0 .477L3.813 5 .099 8.713a.337.337 0 0 0 0 .477l.71.71a.337.337 0 0 0 .477 0L5 6.187 8.713 9.9a.337.337 0 0 0 .478 0l.71-.71a.337.337 0 0 0 0-.477z"
  />
</svg>
